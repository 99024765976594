

// import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
// import {ref} from "vue";
import router from "@/router";
import {ref} from "vue";
import {useStore} from "vuex";
// import Swal from "sweetalert2"
// import SiteService from "@/service/siteService";
import {useToast} from "primevue/usetoast";

export default {
  data() {
    return {}
  },
  setup() {
    const submitted = ref(false);
    const email = ref('');
    const store = useStore();
    const toast = useToast();
    const retour = ref('<-')
    const connexion = () => {
      router.push({name: 'login'})
    }
    const resetPassword = () => {

      submitted.value = true
      if (email.value) {
        store.dispatch(Actions.FORGOT_PASSWORD, {email: email.value}).then(() => {
          toast.add({
            severity: 'success',
            detail: 'Un mail a été envoyé avec succès',
            life: 3000
          });
          setTimeout(() => {
            router.push({name: 'login'})
          }, 3000);

        })
      }
    }
    return {
      connexion,
      resetPassword,
      submitted,
      email,
      store,
      toast,
      retour
    };
  },

}
