import {Site} from "@/store/modules/SiteModule";

const ID_SITE = "id_site";
const NAME_SITE = "name_site";
const TYPE_SITE = "type_site";

/**
 * @description get token form localStorage
 */
export const getIdSite = (): string | null => {
  return window.localStorage.getItem(ID_SITE);
};

/**
 * @description get token form localStorage
 */
export const getNameSite = (): string | null => {
  return window.localStorage.getItem(NAME_SITE);
};

/**
 * @description get token form localStorage
 */
export const getTypeSite = (): string | null => {
  return window.localStorage.getItem(TYPE_SITE);
};


/**
 * @description save token into localStorage
 * @param id: string
 */
export const saveSite = (site: Site): void => {
  window.localStorage.setItem(ID_SITE, site.id);
  window.localStorage.setItem(NAME_SITE, site.name);
  if(site.type){
  window.localStorage.setItem(TYPE_SITE, (site.type).toUpperCase());
  }
};

/**
 * @description remove token form localStorage
 */
export const destroyIdSite = (): void => {
  window.localStorage.removeItem(ID_SITE);
};

/**
 * @description remove token form localStorage
 */
export const destroyNameSite = (): void => {
  window.localStorage.removeItem(NAME_SITE);
};
/**
 * @description remove token form localStorage
 */
export const destroyTypeSite = (): void => {
  window.localStorage.removeItem(TYPE_SITE);
};

export default { getIdSite, getNameSite, getTypeSite, saveSite, destroyIdSite, destroyNameSite, destroyTypeSite };
