<template>
	<div class="layout-footer flex justify-content-between">
		<div class="footer-text-left">
     <img src="../public/layout/images/dashboard/logo-footer.png">
		</div>
<!--		<div class="footer-text-right">-->
<!--			<button class="p-link"><i class="pi pi-facebook"></i></button>-->
<!--			<button class="p-link"><i class="pi pi-twitter"></i></button>-->
<!--			<button class="p-link"><i class="pi pi-github"></i></button>-->
<!--		</div>-->
	</div>
</template>

<script>
	export default {
		name: "AppFooter"
	}
</script>

<style scoped>

</style>
