

import {useStore} from "vuex";
// import {Actions} from "@/store/enums/StoreEnums";
import {ref} from "vue";
import {Actions} from "@/store/enums/StoreEnums";
import {useRoute} from "vue-router";
import router from "@/router";
import {useToast} from "primevue/usetoast";
export default {
  data() {
    return {}
  },
  setup() {
    const store = useStore();
    let plain_password = ref('')
    let confirm_password = ref('')
    let submitted = ref(false)
    let confirm = ref(false)
    const toast = useToast();
    const route = useRoute();
    const resetPassword = () => {

      submitted.value = true
      confirm.value = true
      if(plain_password.value)
      {
        if(plain_password.value != confirm_password.value)
        {
          confirm.value = false
        }
      }

      let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\]{};':"\\|,.<>?~])(?=.{8,})/;
      let token = route.params.token
      if (re.test(plain_password.value) && confirm.value){
        store.dispatch(Actions.FORGOT_PASSWORD_RESET_PWD, {password: {password: plain_password.value}, token: token}).then(()=>{

          toast.add({
            severity: 'success',
            detail: 'Le mot de passe a été modifié avec succès',
            life: 3000
          });
          setTimeout(() => {
            router.push({name: 'login'})
          }, 3000);
        })
      }
    }
    const  checkPassword = (str : any) =>
    {

        let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\]{};':"\\|,.<>?~])(?=.{8,})/;
        return re.test(str);

    }
    return {
      store,
      plain_password,
      confirm_password,
      submitted,
      resetPassword,
      checkPassword,
      route,
      confirm
    }
  },
  methods: {

  }

}
