//const ID_TOKEN_KEY = "id_token" as string;
const ROLE_USER = "role_user";
const APPLICATION_MASTER = "application_master";

/**
 * @description get role user form localStorage
 */
export const getRoleUser = (): string | null => {
    return window.localStorage.getItem(ROLE_USER);
};

/**
 * @description save role user into localStorage
 * @param roleUser: string
 */
export const saveRoleUser = (roleUser: string): void => {
    window.localStorage.setItem(ROLE_USER, roleUser);
};

/**
 * @description remove role user form localStorage
 */
export const destroyRoleUser = (): void => {
    window.localStorage.removeItem(ROLE_USER);
};

/**
 * @description get application master form localStorage
 */
export const getApplicationMaster = (): string | null => {
    return window.localStorage.getItem(APPLICATION_MASTER);
};

/**
 * @description save application master into localStorage
 * @param applicationMaster: string
 */
export const saveApplicationMaster = (applicationMaster: string): void => {
    window.localStorage.setItem(APPLICATION_MASTER, applicationMaster);
};

/**
 * @description remove application master form localStorage
 */
export const destroyApplicationMaster = (): void => {
    window.localStorage.removeItem(APPLICATION_MASTER);
};
export default { getRoleUser, saveRoleUser, destroyRoleUser, getApplicationMaster, saveApplicationMaster, destroyApplicationMaster };
