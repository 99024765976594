import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

export default class HotlineService {
    sendDiscussion(data: any) {
        return store.dispatch(Actions.SEND_DISCUSSION, data);
    }
    uploadFile(data: any) {
        return store.dispatch(Actions.UPLOAD_FILE_DISCUSSION, data);
    }
    getDiscussionList(data: any) {
        return store.dispatch(Actions.GET_DISCUSSION_LIST, data);
    }

    getMessageByDiscussionId(data: string) {
        return store.dispatch(Actions.GET_MESSAGE_BY_DISCUSSION_ID, data);
    }

    sendMessage(data: any) {
        return store.dispatch(Actions.SEND_MESSAGE, data);
    }

    readMessage(id: string, payload: any) {
        const data =  {
            id: id,
            payload: payload
        }
        return store.dispatch(Actions.READ_MESSAGE, data);
    }

    getForum(data: any) {
        return store.dispatch(Actions.GET_FORUM, data);
    }
}
