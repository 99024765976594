import {en} from './en'
import {fr} from './fr'
import store from '../index'
const locales = {
  en,
  fr
};
export default {
    t: (string: string) => {
        // @ts-ignore
        if (typeof locales[store.state.currentLanguage] === "undefined") {
            return string;
        }
        // @ts-ignore
        if (typeof locales[store.state.currentLanguage][string] === "undefined") {
            return string;
        }
        // @ts-ignore
        return locales[store.state.currentLanguage][string];
    }
};
