import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "../service/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  public static token: string;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    this.setHeader();
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setCustomToken(token: string): void {
    this.token = token
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  /**
   * @description set the default HTTP request headers
   */
  public static removeAuthHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = ``;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(

      resource: string, customHeader: any

  ): Promise<AxiosResponse> {

    // const regex = new RegExp(/^sites\/(\d+)$/gm);
    // const regex = new RegExp(/^documents\/(\d+)$/gm);
    // const regex = new RegExp(/^documents/);
    // const regexSection = new RegExp(/^sections/);
    // const regexCompliance = new RegExp(/^compliances/);
    // const regexSite = new RegExp(/^sites/);

    // if (regex.test(resource) ){
    if (customHeader){
      ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
          "application/ld+json";
    } else {
      ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
          "application/json";
    }


    return ApiService.vueInstance.axios.get(`${resource}`);

  }


  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    const regex = new RegExp(/^documents/gm);
    //
    // if (regex.test(resource)){
    //   ApiService.vueInstance.axios.defaults.headers.common["content-type"] =
    //       "application/ld+json";
    // }
    // else {
    //   ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
    //       "application/json";
    // }


    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static postFormData(
    resource: string,
    params: AxiosRequestConfig,
    customHeader: any,
  ): Promise<AxiosResponse> {
    const regex = new RegExp(/^documents/gm);
    //
    // if (regex.test(resource)){
    //   ApiService.vueInstance.axios.defaults.headers.common["content-type"] =
    //       "application/ld+json";
    // }
    // else {
    //   ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
    //       "application/json";
    // }


    return ApiService.vueInstance.axios.post(`${resource}`, params, customHeader);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }


  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static fetch(

      resource: string

  ): Promise<AxiosResponse> {
    const headers = {
      'Authorization': `Bearer ${this.token}`,
      'My-Custom-Header': 'foobar'
    };
    ApiService.vueInstance.axios.defaults.headers.common[
        "Authorization"
        ] = `Bearer ${this.token}`;
    return ApiService.fetch(`${resource}`);

  }

}

export default ApiService;
