<template>
  <Login v-if="$route.path === '/login'"/>
  <ResetPassword v-else-if="$route.path === '/ui-ax/rest-password'"/>
  <ChangePassword v-else-if="$route.path.includes('/change-password/')"/>
  <Error v-else-if="$route.path === '/error'"/>
  <Access v-else-if="$route.path === '/access'"/>
  <NotFound v-else-if="$route.path === '/notfound'"/>
  <Wizard v-else-if="$route.path === '/wizard'"/>
  <App v-else :theme="theme" :layoutScheme="layoutScheme" :layoutColor="layoutColor" :darkMenu='darkMenu'
       @menu-color-change="menuColorChange"
       @theme-change="changeTheme" @layout-change="changeLayout" @layout-scheme-change='changeLayoutScheme'/>
</template>

<script>
import App from './App.vue';
import Error from './pages/Error';
import Access from './pages/Access';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Wizard from './pages/Wizard';
import ResetPassword from "@/pages/ResetPassword";
import ChangePassword from "./pages/ChangePassword";
// import {useRouter} from "vue-router";

export default {
  data() {
    return {
      theme: 'purple',
      layoutScheme: 'light',
      layoutColor: 'purple',
      darkMenu: false
    }
  },
  // setup() {
  //   const router = useRouter()
  //   console.log(router)
  //   console.log(router.currentRoute)
  //   console.log(router.currentRoute._value , 'eyety')
  //   // const token = 'f15583661c17046a6594892830d3ad02096dc4e2c79fab8cc5'
  //   // console.log(lastIndexOf(window.location.href))
  //
  //
  //   let path = window.location.href
  //   // console.log(path)
  //   // console.log(path.lastIndexOf('change-password/')+16, 'indexx')
  //   // console.log(path[path.lastIndexOf('change-password/')] )
  //
  //   let token = path.substring(path.lastIndexOf('change-password/')+16, path.length)
  //   // console.log(token)
  //   return {token}
  // },
  methods: {
    menuColorChange(menuColor) {
      this.darkMenu = menuColor;
    },
    changeLayoutScheme(scheme) {
      this.layoutScheme = scheme;
      this.darkMenu = scheme === 'dark';

      const themeLink = document.getElementById('theme-css');
      const urlTokens = themeLink.getAttribute('href').split('/');
      urlTokens[urlTokens.length - 1] = 'theme-' + this.layoutScheme + '.css';
      const newURL = urlTokens.join('/');

      this.replaceLink(themeLink, newURL);
    },
    changeTheme(theme) {
      this.theme = theme;

      const themeLink = document.getElementById('theme-css');
      const themeHref = '/theme/' + theme + '/theme-' + this.layoutScheme + '.css';
      this.replaceLink(themeLink, themeHref);
    },
    changeLayout(layout) {
      this.layoutColor = layout

      const layoutLink = document.getElementById('layout-css');
      const layoutHref = '/layout/css/layout-' + layout + '.css';
      this.replaceLink(layoutLink, layoutHref);
    },
    replaceLink(linkElement, href) {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');

      linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id);
      });
    },
  },
  components: {
    ChangePassword,
    ResetPassword,
    "App": App,
    "Error": Error,
    "Access": Access,
    "Login": Login,
    "NotFound": NotFound,
    "Wizard": Wizard
  }
}
</script>

<style scoped>
</style>
