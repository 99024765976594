const LANG = "language";

/**
 * @description get token form localStorage
 */
export const getLang = (): string | null => {
    return window.localStorage.getItem(LANG);
};

/**
 * @description save token into localStorage
 * @param id: string
 */
export const saveLAng = (id: string): void => {
    window.localStorage.setItem(LANG, id);
};

/**
 * @description remove token form localStorage
 */
export const destroyLang = (): void => {
    window.localStorage.removeItem(LANG);
};

export default { getLang, saveLAng, destroyLang };
