import {Actions, Mutations} from "../enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

export interface Tools {
    idTheme: string;
    idDomain: string;
    idSubDomain: string;
    text: string;
    dated_at: string;
    last_updated_at: string;
    search: boolean;
}

@Module
export default class ToolsModule extends VuexModule  {
    errors = {};
    tool = {} as Tools;

    /**
     * Get idTheme object
     * @returns Tools
     */
    get toolsCurrent(): Tools {
        return this.tool;
    }

    @Mutation
    [Mutations.SET_ID_THEME](tool: Tools) {

        this.tool = tool;
        this.errors = {};
        // siteService.saveSite(site);

    }

    @Action
    [Actions.GET_ID_THEME]() {
        return this.tool
    }


}
