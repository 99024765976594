import {Actions, Mutations} from "../enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import siteService from "@/service/siteService";

export interface Site {
    id: string;
    name: string;
    type: string;
}

@Module
export default class SiteModule extends VuexModule  {
    errors = {};
    site = {} as Site;

    /**
     * Get current user object
     * @returns Site
     */
    get currentSite(): Site {
        return this.site;
    }

    @Mutation
    [Mutations.SET_SITE](site: Site) {

        this.site = site;
        this.errors = {};
        siteService.saveSite(site);

    }

    @Action
    [Actions.GET_SITE]() {
        return this.site
    }


}
